/* styles.css will be extracted to main dist folder and is imported in index.html */
/* This file is for importing 3rd party styles  */

@import 'variables';
@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the base styles for Angular Material core. We include this here so that you only
// have to load a single css file for Angular Material in your app.
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$primary: mat-palette($mat-blue);
$accent:  mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$warn:    mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$theme: mat-light-theme($primary, $accent, $warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($theme);

@media only screen and (min-width: 800px) {
  mat-sidenav.mat-sidenav-opened, mat-sidenav.mat-sidenav-opening {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.08),0px 1px 1px 0px rgba(0, 0, 0, 0.07),0px 1px 3px 0px rgba(0, 0, 0, 0.14) !important;
  }
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: $primary-font;
  font-size: 14px;
  font-weight: 400;
  // line-height: 20px;
}

pre {
  font-family: $primary-font;
  font-size: 14px;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

/**
  * Basic styles from Material Design Lite for HTML elements
  */
  h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0; }

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
  font-family: $primary-font;
  font-size: 56px;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -0.02em;
  opacity: 0.54;
  font-size: 0.6em; }

h1 {
  font-family: $primary-font;
  font-size: 56px;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -0.02em;
  margin-top: 24px;
  margin-bottom: 24px; }

h2 {
  font-family: $primary-font;
  font-size: 45px;
  font-weight: 400;
  line-height: 48px;
  margin-top: 24px;
  margin-bottom: 24px; }

h3 {
  font-family: $primary-font;
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  margin-top: 24px;
  margin-bottom: 24px; }

h4 {
  font-family: $primary-font;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 24px;
  margin-bottom: 16px; }

h5 {
  font-family: $primary-font;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.02em;
  margin-top: 24px;
  margin-bottom: 16px; }

h6 {
  font-family: $primary-font;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.04em;
  margin-top: 24px;
  margin-bottom: 16px; }

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0;
  margin-bottom: 0; }

a {
  font-weight: 500; }

blockquote {
  font-family: $primary-font;
  position: relative;
  font-size: 24px;
  font-weight: 300;
  font-style: italic;
  line-height: 1.35;
  letter-spacing: 0.08em; }
  blockquote:before {
    position: absolute;
    left: -0.5em;
    content: '“'; }
  blockquote:after {
    content: '”';
    margin-left: -0.05em; }

mark {
  background-color: #f4ff81; }

dt {
  font-weight: 700; }

address {
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
  font-style: normal; }

ul, ol {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0; }

  .cursorP{
    cursor:pointer;
    height: 1.5rem;
  }

  .textWrap{
    text-wrap: wrap !important;
  }

  .font15{
    font-size: 15px!important;
  }

  .dropdownDisabled{
    pointer-events: none;
  }

  .dropdownEnabled{
    pointer-events: all;
  }

.mTop4em{
  margin-top: 0.4rem;
}

.dpWidth{
  width:inherit;
}

.mat-card, .uc-message-mat-card{
  font-family: $primary-font !important;
  background: #f2f4f7;
}

.minH35{
  min-height: 3.5rem;
}

.mat-form-field.mat-focused .mat-form-field-ripple{
  background-color: #cc0000 !important;
}

.cdk-overlay-pane.uvi-mat-menu-overlay-panel {
  margin-top: unset;
}

.dd-vehicle-summary-display {
  .mat-card-header {
    .mat-card-title {
      font-size: unset;
    }
  }
}